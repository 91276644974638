const lines = [
  " makes the ship run",
  " steers the ship",
  " keeps this thing in the sky",
  " keeps this place running",
  " is a force to be reckoned with",
  " is the lone voice of reason in an otherwise insane world",
  " is a blizzard that actually shows up",
  " is like a memory of summer in a winter that seems to go on forever",
  " is the smell of clean laundry",
  " is a Netflix login that your friends being cool and letting you use for a while",
  " is a comforter accidentally warmed by the radiator",
  " is the smell of my grandma's kitchen",
  " is the band you discovered in highschool long before anyone else did",
  " is a catharsis that you only reach after years of therapy and after you've almost given up on yourself",
  "... Is a pregnant pause before good news",
  " is a three day weekend",
  " is a buzzer beater at half court",
  " is a nap in a hammock",
  " is a gift you weren't expecting",
  " is a window in a room where you didn't think there was one",
  " is something you think is impossible, but you have to try anyway",
  " is the confidence to fight the self-doubt that keeps you from being yourself",
  " is the T-shirt you don’t wash because you’re afraid it will shrink",
  " is the free garlic knots with your pizza",
  " is your friend’s swimming pool, he said its okay if you come by",
  " is that rock quarry you’ve been swimming at for years, and still no one else seems to know about it",
  " is that moment when you realize that person you’ve been crushing on all semester, might just like you back",
  " is a new haircut that’s not all messed up and weird",
  " is an old movie that still holds up",
  " is the solution to a problem that’s been bothering you for a while",
  " is Estonia",
  " is that moment when the band you love comes out for an encore.",
  " is reading the credits",
  " is a jump off a high dive",
  " is a night to yourself just long enough so you remember you like other people",
  " is a book you stay up and read in one night",
  " is that cousin you only see during the summer but you get along as though you hang out all the time",
  " is good advice from an old friend",
  " is waking up in a tent so far from civilization that you cant even hear the distant hush of tires",
  " is a surprise party that you secretly hoped for, but you never let yourself expect",
  " is a piper at the gates of dawn",
  " is a rope swing over a pond",
  " is a conversation so engrossing that the only thing that snaps you out of it, is the sun coming up",
  " is a spontaneous road trip",
  " is finally finding the name of that movie that you saw as a kid that was so weird and vivid that you didn't know if you dreamt it or not",
  " is when you run into a friend on the street and you end up just spending the day walking around",
  " is the quiet stillness of the year's first snowfall",
  " is the phone that you dropped out of your hand onto the sidewalk, pick up in sheer horror, look at and realize that it is somehow completely unharmed",
  " is one of those nights that is so fun and gets so out of hand that when you describe it to your friends you can tell that they don't totally believe you",
  " is the person who can fix the things that are broken and complicated",
  " is a word you finally remember",
  " is the first day of the year where everyone walks around carrying the coats that they just realized they don't need to wear",
  " is a killer drum break",
  " is one more hour of sleep",
  " is light itself - coursing throughout the city spreading information",
  " is an animated gif of a mailman furiously beating a mailbox with a baseball bat",
  " is the chairman of Wayne enterprises",
  " is a weekend with good weather and no plans",
  " is the salsa dancer emoji",
  " is a mouse pad with a kitten in a basket on it",
  " is the first day of the year that you can fall asleep with the window open",
  " is a new puppy that finally sleeps through the night",
  " is a nap in a park",
  " is the smoke from fireworks",
  " is a summer night where you somehow get the ratio of thin sheet to heavy sheet and air conditioner to window to finally line up and you are not hot and you are not too cold and you actually sleep",
  " is tubing down a lazy river, towing a 6 pack",
  " is a plate of very fancy cold cuts",
  " is a long lunch",
  " is cicada season",
  " is a three day weekend you COMPLETELY forgot about",
  " is the sound of your favorite person laughing",
  " is a dingy hotel in a small town that somehow has your favorite pinball game",
  " is the directors cut of Aliens. NOT the theatrical release",
  " is a perfect Halloween costume",
  " is money you find in a winter coat",
  " is a new season of a long cancelled television show that's actually as good as it was when it was originally on the air",
  " is a late night phone call from a friend",
  " is a space heater on cold feat",
  " is launching your sled off a killer jump",
  " is your first big laugh at the office after a long vacation",
  " is a cure for what ails you",
  " is a world that doesn't make sense in a way that's safe and joyful and feels really good",
  " is driving with the windows down singing your favorite song as loud as you can",
  " is hoodie weather",
  " is a miraculous parking spot",
  " is four extra beers in the fridge that you forgot were even there",
  " is a new kind of sandwich you've never seen before",
  " is a lost t-shirt that just shows up one day",
  " is the first cookout of the year",
  " is the satisfaction of pressing send on an email that you've agonized over for a year",
  " is a balmy summer night just hanging out on the back porch, doing whatever",
  " is bubble tea",
  " is a playlist that has that Beach Boys song 'Wind Chimes' on it, but then right after that song ends, 'Snowblind' by Black Sabbath comes on",
  " is a potluck dinner on a picnic table in the shade on a balmy evening",
  " is an all mango fruit basket",
  " is a book you read in one sitting",
  " is the best part of the summer",
  " is a bonfire on a beach",
  " is a job that you love so much that you actually sort of forget to go outside. and then one day you just find yourself wondering 'Has the 4th of July happened yet?' and so you look out a window and you realize actually its the end of august, summers almost over and you just feel this tiny little teaspoon of regret. And then you realize, you could just go outside. The building has an elevator. What if you took it?",
  " is those Popsicle's you thought you were never going to find at the deli again",
  " is one last softball game at the end of summer",
  " is getting back on your bike after you haven't ridden it in a really long time",
  " is when you're sitting on the couch flipping thru channels and you find a movie you haven't seen in a long time and you just sit there and watch the whole thing",
  " is a dog who sleeps on your keyboard",
  " is sitting on your couch and looking around at how nice your house looks after you just cleaned it",
  " is cookies somebody brought to the office",
  " is a room that is at the perfect temperature",
  " is snow before you get sick of it",
  " is the day where everybody brings their extra baked goods to the office",
  " is an unseasonably warm day",
  " is a vending machine that gives you two of the thing you wanted by mistake",
  " is candy you've never heard of from some other country",
  " is listening to the radio and hearing that song you've heard sampled a million times, but you never knew the name of",
  " is that week where it finally gets warm enought that the last of the snow melts",
  " is the closest parking spot to the movie theater",
  " is food that is the appropriate amount of spicy",
  " is just getting to do credits in a normal way without some weird interloper coming in the room",
  " is the incredible satisfation of making that someone special laugh",
  " is your favorite group chat",
  " is going to see a summer blockbuster on a 100 degree day in a theater that has its air conditioning cranked way up",
  " is driving with the windows down and the music up",
  " is a thermostat at a temperature that everyone in the room can agree on",
  " is 10 hours of sleep",
  " is a walk in the woods",
  " is a long drive at night",
  " is a video that you find so funny that you force your coworkers to watch over and over and over again until they start enjoying it",
  " is getting a phone call and having it not turn out to be spam",
  " is a good excuse to get dressed up",
  " is when you're folding your laundry and find 5 bucks in one of your pants pockets",
  " is one of those cotton candy machines where you get to dump in the like, cotton candy mixture and then you turn it on and you put a stick in there and cotton candy magically weaves out of thin air onto the stick",
  " is one of those free samples of fudge you get on the boardwalk by the beach",
  " is Sunday at the farmer's market",
  " is a random interaction with a stranger where afterward, you don't feel awkward",
];

export default () => {
  const length = lines.length;
  return lines[Math.floor(Math.random() * length)];
};
